export const greeting = [ "Hi," ];
export const myName = [ "I'm Alexis" ];

export const presentation = `I am a Full Stack developer. Currently, I live in Buenos Aires, Argentina. 
        I started studying on my own in 2021. I got certificated in <a target="_blank" href="https://www.coderhouse.com/certificados/62adeb50a5532c0021cdd0b3?lang=en">Web Development</a>, 
<a target="_blank" href="https://www.coderhouse.com/certificados/62e372522f35b30019258f00?lang=en">JavaScript</a>, 
<a target="_blank" href="https://www.coderhouse.com/certificados/6340098a61b9e7000e8d226b?lang=en">React JS</a> and
<a target="_blank" href="https://www.coderhouse.com/certificados/6458b804a389450002290eca">Node JS</a> at 
<a target="_blank" href="https://www.coderhouse.com/">CoderHouse</a>.`;



